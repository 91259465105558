// CAUTION: Do not edit this file. It has been generated automatically.
// NOTE: You can add more icons by editing config/fontawesome.yml and
// running `bin/fontawesome` after.
import { dom, library } from '@fortawesome/fontawesome-svg-core'
import { faArrowDown as farArrowDown } from '@fortawesome/pro-regular-svg-icons/faArrowDown'
import { faArrowUp as farArrowUp } from '@fortawesome/pro-regular-svg-icons/faArrowUp'
import { faBuilding as farBuilding } from '@fortawesome/pro-regular-svg-icons/faBuilding'
import { faCalendar as farCalendar } from '@fortawesome/pro-regular-svg-icons/faCalendar'
import { faCaretRight as farCaretRight } from '@fortawesome/pro-regular-svg-icons/faCaretRight'
import { faDownload as farDownload } from '@fortawesome/pro-regular-svg-icons/faDownload'
import { faGear as farGear } from '@fortawesome/pro-regular-svg-icons/faGear'
import { faPaperclip as farPaperclip } from '@fortawesome/pro-regular-svg-icons/faPaperclip'
import { faSearch as farSearch } from '@fortawesome/pro-regular-svg-icons/faSearch'
import { faSquareArrowUpRight as farSquareArrowUpRight } from '@fortawesome/pro-regular-svg-icons/faSquareArrowUpRight'
import { faTrash as farTrash } from '@fortawesome/pro-regular-svg-icons/faTrash'
import { faUpload as farUpload } from '@fortawesome/pro-regular-svg-icons/faUpload'
import { faUser as farUser } from '@fortawesome/pro-regular-svg-icons/faUser'
import { faWarning as farWarning } from '@fortawesome/pro-regular-svg-icons/faWarning'
library.add(
  farArrowDown,
  farArrowUp,
  farBuilding,
  farCalendar,
  farCaretRight,
  farDownload,
  farGear,
  farPaperclip,
  farSearch,
  farSquareArrowUpRight,
  farTrash,
  farUpload,
  farUser,
  farWarning
)
dom.watch()
